#vgame {
    display: flex;
    margin: 0 auto;
    padding: 10px 0 30px;
    text-align: center;
}

#statusDisplay {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.handCard {
    width: 120px;
    height: 190px;
    background: url(../images/vp2.svg) no-repeat center;
    box-shadow: 2px 2px 3px #5c6f9;
    border-radius: 0.125em;
    font-size: 4em;
    font-weight: bold;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px !important;
    background-size: cover;
    -webkit-transition: all 120ms ease-in;
    transition: all 120ms ease-in;
}

.handCard:hover {
    filter: contrast(1.1);
    transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.handCardSpacer {
    width: 1em;
    height: 1em;
    float: left;
    visibility: hidden;
}

.handCard-modal {
    width: 50px;
    height: 20px;
    position: relative;    /*background: #17202e;*/
    font-size: 20px;
    font-weight: bold;
    opacity: .9;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    align-items: center;
    -webkit-transition: all 120ms ease-in;
    transition: all 120ms ease-in;
}

.holder.handCard-modal:before {
    content: "Hold";
    font-size: 11px;
    position: absolute;
    left: 0;
    right: 0;
    color: #ffeb3b;
    bottom: 0;
    transform: rotate(-45deg);
    padding: 0px 10px;
    z-index: 111111111;
    font-weight: 700;
}

#bottom {
    clear: both;
}

#displays {
    clear: both;
}

#moneyDisplay {
    float: left;
    font-size: 2em;
    width: 15em;
    text-align: left;
    padding: 0.5em 0 0 0;
    margin: auto auto 0 0;
    color: #fff;
    text-shadow: 0.1em 0.1em #000;
    font-weight: bold;
}

#wagerDisplay {
    font-size: 2em;
    text-align: right;
    padding: 0.5em 0 0 0;
    color: #fff;
    text-shadow: 0.1em 0.1em #000;
    font-weight: bold;
}

#buttons {
    clear: both;
    padding-top: 1em;
}

#dealButton {
    background: #c33;
    height: 1.5em;
    width: 8em;
    color: #fff;
    text-shadow: 0.12em 0.12em #000;
    font-weight: bold;
    font-size: 3em;
    float: left;
    margin-right: 2em;
    box-shadow: 0.16em 0.16em 0.16em #000;
    border-radius: 0.16em;
}

#wagerButtonUp {
    background-color: #09c;
    height: 1.5em;
    width: 1.5em;
    font-size: 3em;
    font-weight: bold;
    color: #fff;
    float: right;
    margin-right: 1em;
    box-shadow: 0.16em 0.16em 0.16em #000;
    border-radius: 0.16em;
}

#wagerButtonDown {
    background-color: #09c;
    height: 1.5em;
    width: 1.5em;
    font-size: 3em;
    font-weight: bold;
    float: right;
    color: #fff;
    box-shadow: 0.16em 0.16em 0.16em #000;
    border-radius: 0.16em;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 0.7em solid transparent;
    border-right: 0.7em solid transparent;
    border-bottom: 0.7em solid #fff;
    margin: 0.35em auto 0 auto;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 0.7em solid transparent;
    border-right: 0.7em solid transparent;
    border-top: 0.7em solid #fff;
    margin: 0.35em auto 0 auto;
}

.poker_container {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
}

.vpoker {
    display: grid;
    grid-gap: 10px;
    margin-top: 40px;
    grid-template-columns: repeat(5, 80px);
    grid-template-rows: repeat(5, 80px);
    grid-auto-flow: column;
    position: relative;
    left: 50%;
    top: 7%;
    transform: translate(-50%);
}

.vpoker div, .vpoker div {
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    color: #FFF;
    background-position: bottom;
}

@media (max-width:1024px) {
    #vgame {
        padding: 10px 0 20px;
    }

    .handCard {
        width: 100%;
        height: 5.5rem;
    }

    .poker_container {
        display: block;
    }

    .poker_container {
        margin-bottom: 0px;
    }

    .vpoker div, .vpoker div {
        font-size: 30px;
        background-size: contain;
    }

    .vpoker {
        grid-gap: 0;
    }

}

.handCard {
    position: relative;
}

.isHold {
}

.isHold:after {
    content: "HOLD";
    background: rgb(0 225 255 / 26%);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 15px;
    border-radius: 5px;
    color: #FFF;
    animation: bounceIn 0.3s linear 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videopoker-table .active {
    background: linear-gradient(-90deg, #ce36f4 0%, transparent 101%);
    text-align: center;
    border-radius: 15px;
}


.videopoker-table .active span {
    color: #FFF !important;
}
