.goal .grids {
    display: inline-flex;
    justify-content: center;
    position: relative;
    width: 100%;
}

.goal .column {
    margin-left: 2px;
    position: relative;
}

.goal .column i {
    position: absolute;
    font-size: 30px;
    top: -45px;
    left: 20px;
    animation: bounceIn 1.5s linear infinite;
}

.goal-numbers {
    width: 70px;
    height: 70px;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 150ms ease-in;
    transition: all 150ms ease-in;
}

.goal-multiplier {
    background: #FFF;
    color: #000;
    border-radius: 5px;
    padding: 1px 5px;
    text-align: center;
    width: 100%;
    display: block;
}

.goal-numbers .btn {
    padding: 4px;
}

.goal-numbers .btn:disabled {
    cursor: default;
}

.goal .btn-soft-secondary {
    background: #fefefe;
    color: #8f9eb7;
    font-size: 17px;
    height: 68px;
    padding: 15px 0px;
    font-weight: 300;
    font-family: 'Titillium Web';
}

.goal-numbers .btn-soft-secondary:focus, .goal-numbers .btn-soft-secondary:active {
    outline: none !important;
    box-shadow: none !important;
}

.goal-numbers:hover > .btn {
    background: #fefefe;
}

.goal-numbers .active {
    background: #4e76e6 !important;
    color: #FFF !important;
    animation: bounceIn 0.3s linear 1;
    -webkit-animation: bounceIn 0.3s linear 1;
    -moz-animation: bounceIn 0.3s linear 1;
    -o-animation: bounceIn 0.3s linear 1;
    -ms-animation: bounceIn 0.3s linear 1;
}

.goal .ball-wrapper {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-shadow: 1px 1px 0 1px rgb(0 0 0 / 50%);
    /*transition: transform 400ms ease 0s;*/
    transition: transform 400ms ease 0s;
}

.ball-animation {
    -webkit-animation-name: ball-rotate;
    animation-name: ball-rotate;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.goal-face {
    background-repeat: repeat-x !important;
    box-shadow: 0px 0px 90px #203035 inset;
    background: radial-gradient(80% 100% at center 100%,rgba(107,184,0,.85),#295120);
    background-position: center; 
    background-size: cover;
    margin-right: .7rem!important;
    margin-left: .7rem!important;
}


@-webkit-keyframes ball-rotate {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes ball-rotate {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes arrow-pulse {
    from,
    to {
        -webkit-transform: scale(1) rotate(45deg);
        transform: scale(1) rotate(45deg);
    }
    50% {
        -webkit-transform: scale(1.1) rotate(45deg);
        transform: scale(1.1) rotate(45deg);
    }
}
@keyframes arrow-pulse {
    from,
    to {
        -webkit-transform: scale(1) rotate(45deg);
        transform: scale(1) rotate(45deg);
    }
    50% {
        -webkit-transform: scale(1.1) rotate(45deg);
        transform: scale(1.1) rotate(45deg);
    }
}
@-webkit-keyframes bomb-detonation {
    from {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes bomb-detonation {
    from {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
